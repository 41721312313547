import * as React from "react";
// import { Link } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout/layout";
import { graphql } from "gatsby";
import { page, Page } from "../classes/Page";
import get from "lodash/get";
import Hero from "../components/ui/Hero";
import { gatsbyImg, Img } from "../classes/Img";
import { RichTextReact } from "../components/RichText/RichTextReact";
import { WmkSeo } from "wmk-seo";

interface dataStructure {
  references: {
    internal: { type: string };
    patternImage?: gatsbyImg;
  };
}
const IndexPage = ({
  data
}: {
  data: {
    page: page;
    patternImage: {
      gatsbyImageData: gatsbyImg["gatsbyImageData"];
      title: string;
    };
    site: { meta: { description: string; siteUrl: string; title: string } };
  };
}) => {
  const page = new Page(data.page);
  const patternImg = new Img(data.patternImage);
  const modifiedPage = get(page, "content.json.content", []).map(
    (block: dataStructure) => {
      if (block?.references?.internal?.type === "ContentfulBlockPattern") {
        block.references.patternImage = data.patternImage;
        return block;
      } else {
        return block;
      }
    }
  );

  return (
    <Layout>
      <WmkSeo.Meta
        title={page.title}
        siteTitle={data.site.meta.title}
        baseUrl={data.site.meta.siteUrl}
        path={""}
        slug={"/"}
        description={data.site.meta.description}
        ogImageUrl={page?.heroImage?.src}
        twitterHandle=""
        twitterImageUrl=""
      />
      <Hero
        heroHeader={page?.heroHeader}
        heroSubheader={page?.heroSubheader}
        heroImage={page?.heroImage}
        title={page?.title}
        patternImage={patternImg}
        isHomeHero
      />
      <RichTextReact content={page?.content} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    page: contentfulPage(title: { eq: "Home" }) {
      ...NodePageFields
      ...PageBlockImageCopy
    }
    patternImage: contentfulAsset(title: { eq: "IT Decorative Tile" }) {
      gatsbyImageData(quality: 80)
      title
    }
    site {
      meta: siteMetadata {
        description
        siteUrl
        title
      }
    }
  }
`;
